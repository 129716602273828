import React from 'react';
import Launch from '../images/launch-24px.svg';

const ExDocLink = ({ link, title }) => (
    <div>
        <p>
            <strong>External documentation:&nbsp;</strong> 
            <a href={link} target="_blank" rel="noopener noreferrer">
                {title}&nbsp;
                <img src={Launch} alt="Opens in new window" />
            </a>
        </p>
    </div>
);

export default ExDocLink;