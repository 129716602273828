import React from 'react'

const Snippet = (props) => (
    <div>
    <h4>{props.title}</h4>
    <pre>
        <code>
            {props.children}
        </code>
    </pre>
    </div>
);

export default Snippet;