import React from 'react';

import Layout from '../../components/layout';
import Snippet from '../../components/snippet';
import PageTitle from '../../components/pagetitle';
import ExDocLink from '../../components/exdoclink';
import Warning from '../../components/warning';
import SEO from '../../components/seo';


const IndexPage = () => (
  <Layout>
    <SEO title="Google Cloud Functions Cheet Sheet" />
    <PageTitle>Google Functions Cheet Sheet</PageTitle>

    <p className="lead">A list of gcloud commands to manage your Google Cloud Functions.</p>

    <Snippet title="List all functions">gcloud functions list</Snippet>
    <ExDocLink title="functions list on Google Cloud docs" link="https://cloud.google.com/sdk/gcloud/reference/functions/list" />

    <Snippet title="Describe function">gcloud functions describe --region europe-west2 function-name</Snippet>
    <ExDocLink title="functions describe on Google Cloud docs" link="https://cloud.google.com/sdk/gcloud/reference/functions/describe" />
    
    <Snippet title="Delete functions">gcloud functions delete --region europe-west2 function-name</Snippet>
    <ExDocLink title="functions delete on Google Cloud docs" link="https://cloud.google.com/sdk/gcloud/reference/functions/delete" />

    <Snippet title="Deploy nodejs 10 function triggered by bucket event">
    gcloud functions deploy function-name `<br />
    --entry-point entry-function `<br />
    --runtime nodejs10 `<br />
    --region europe-west2 `<br />
    --trigger-bucket=bucket-name `<br />
    </Snippet>
    <ExDocLink title="functions deploy on Google Cloud docs" link="https://cloud.google.com/sdk/gcloud/reference/functions/deploy" />
    
    <h4>Cloud Functions Regions</h4>
    <p>By January 2020 Cloud Functions are available in these regions:</p>
    <li>us-central1</li>
    <li>us-east1</li>
    <li>us-east4</li>
    <li>europe-west1</li>
    <li>europe-west2</li>
    <li>asia-east2</li>
    <li>asia-northeast1</li>


  </Layout>
)

export default IndexPage