import React from 'react';
import Warningsign from '../images/warning-24px.svg';

const Warning = (props) => (
    <div class="alert alert-warning" role="alert">
        <div className="row">
            <div className="col-sm-1">
                <img src={Warningsign} alt="Warning" />
            </div>
            <div className="col-sm-11">
                {props.children}
            </div>
        </div>
    </div>
);

export default Warning;
